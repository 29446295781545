<template>
  <div>
    <div class="first_row">
      <div class="first_card">
        <div class="card_div user_avatar">
          <el-image :src="user_head_img"></el-image>
        </div>
        <div class="card_div">
          <span style="color: #1c2237">欢迎您回来，{{ user_name }}</span>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="card_div">
          <el-link type="primary" @click="goto_address">我的地址</el-link>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="card_div">
          <el-link type="primary" @click="goto_pay_account_manage">支付账号管理</el-link>
        </div>
      </div>
      <div class="second_card">
        <div class="calender">我的日历</div>
        <div class="date_half">
          <h1>{{ curr_day }}</h1>
          <div>{{ curr_week }}</div>
          <div>{{ curr_date }}</div>
        </div>
      </div>
    </div>
    <div class="second_row">
      <div class="third_card">
        <div class="wallet_text">
          <span style="margin-left: 10px">我的钱包</span>
          <el-switch style="margin-right: 10px" v-model="wallet_status" @change="changeWalletStatus"></el-switch>
        </div>
        <div>
          <el-descriptions direction="vertical" :column="4" border>
            <el-descriptions-item label="账户余额">{{ balance }}</el-descriptions-item>
            <el-descriptions-item label="慕豆">0</el-descriptions-item>
            <el-descriptions-item label="白条本月待还">0</el-descriptions-item>
            <el-descriptions-item label="小金库昨日收益">0</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="fourth_card">
        <div class="wallet_text">
          <span style="margin-left: 10px">我的订单</span>
        </div>
        <div class="order_button">
          <el-badge :value="to_pay_count" class="item" :hidden="to_pay_count <= 0">
            <el-button size="small">待付款</el-button>
          </el-badge>
          <el-badge :value="to_send_count" class="item" :hidden="to_send_count <= 0">
            <el-button size="small">待发货</el-button>
          </el-badge>
          <el-badge :value="to_confirm_count" class="item" :hidden="to_confirm_count <= 0">
            <el-button size="small">待确认</el-button>
          </el-badge>
          <el-badge :value="to_evaluation_count" class="item" :hidden="to_evaluation_count <= 0">
            <el-button size="small">待评价</el-button>
          </el-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      to_confirm_count: 0,
      to_evaluation_count: 0,
      to_pay_count: 0,
      to_send_count: 0,
      balance: 0,
      wallet_status: true,
      curr_day: '',
      curr_week: '',
      curr_date: '',
      user_name: '',
      user_head_img: ''
    }
  },
  created() {
    this.getUserInfo()
    this.getDate()
    this.getWalletInfo()
    this.getOrderStatusCount()
  },
  methods: {
    getOrderStatusCount() {
      this.$api.order.getOrderStatusCount().then(res => {
        console.log(res.data)
        this.to_confirm_count = res.data.data.to_confirm_count
        this.to_evaluation_count = res.data.data.to_evaluation_count
        this.to_pay_count = res.data.data.to_pay_count
        this.to_send_count = res.data.data.to_send_count
      })
    },
    changeWalletStatus() {
      if (this.wallet_status === true) {
        this.$api.account.openWallet().then(res => {
          if (res.data.code === 200) {
            this.$message.success({
              message: '开启钱包成功',
              center: true,
              duration: 2000
            })
          } else {
            this.$message.error({
              message: res.data.msg,
              center: true,
              duration: 5000
            })
          }
        })
      } else {
        this.$api.account.closeWallet().then(res => {
          if (res.data.code === 200) {
            this.$message.success({
              message: '关闭钱包成功',
              center: true,
              duration: 2000
            })
          } else {
            this.wallet_status = true
            this.$message.error({
              message: res.data.msg,
              center: true,
              duration: 5000
            })
          }
        })
      }
    },
    getWalletInfo() {
      this.$api.account.getWalletInfo().then(res => {
        if (res.data.code === 200008) {
          this.wallet_status = false
        } else {
          this.balance = res.data.data.balance
        }
      })
    },
    goto_pay_account_manage() {
      this.$router.push('/pay_account_manage')
    },
    goto_address() {
      this.$router.push('/address_manage')
    },
    getDate() {
      this.$api.user.getDate().then(res => {
        this.curr_day = res.data.data.day
        this.curr_week = res.data.data.week
        this.curr_date = res.data.data.date
      })
    },
    getUserInfo() {
      this.$api.user.getLoginUserInfo().then(res => {
        this.user_head_img = res.data.data.head_img
        console.log(this.user_head_img)
        if(this.user_head_img===null){
          this.user_head_img="http://47.101.216.239:9090/product/head_img.jpeg"
        }
        this.user_name = res.data.data.user
      })
    }
  }
}
</script>

<style scoped>
.date_half {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8fffa;
  color: #55b46c;
}
.calender {
  color: #fff;
  height: 40px;
  background-color: #6ec884;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second_card {
  /*height: 20%;*/
  width: 30%;
  /*background-color: #f5f8fa;*/
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 5px;
  border-width: 1px;
  margin-left: 10px;
  background-color: #f8fffa;
}
.first_row {
  display: flex;
  flex-direction: row;
}
.second_row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.card_div {
  margin: 20px;
  color: #8c939d;
}
.user_avatar {
  height: 150px;
  width: 150px;
}
.first_card {
  /*height: 20%;*/
  width: 70%;
  background-color: #f5f8fa;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 5px;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.third_card {
  width: 50%;
  height: 143px;
  background-color: #f5f8fa;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 5px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
}
.fourth_card {
  width: 50%;
  height: 143px;
  background-color: #f5f8fa;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 5px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.wallet_text {
  color: #454545;
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #d9d9d9;
  justify-content: space-between;
}
.order_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
}
.item {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
